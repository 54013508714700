import React, { useEffect, useMemo, useState } from "react";
import "./home.scss";
import httpclient from "../../service/httpclient";
import List from "../../components/lists/list";
import SimpleScrollBar from "../../components/ScrollBar/SimpleScrollBar";
import NavigationBar from "../../components/NavigationBar/navigationbar";
import { NavLink, useLocation } from "react-router-dom";
import { RightOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import routeUrls from "../../route/routeurls";
import moment from "moment";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home() {
  const [CategoriesData, setCategoriesData] = useState(null);
  const [childData, setChildData] = useState({});
  const [learningProgressData, setLearningProgressData] = useState(null);
  const categoriesid = 1;
  let query = useQuery();
  const selected = query.get("selected");
  useEffect(() => {
    httpclient.getCategoriesData(categoriesid).then(async (data) => {
      setCategoriesData(data);
      if (data?.children) {
        const child_data = {};
        for (let child of data.children) {
          await httpclient.getCategoriesData(child.id).then((res) => {
            const temp = [];
            for (let item of res?.children) {
              if (
                item.id !== 38 &&
                item.id !== 16 &&
                item.id !== 44 &&
                item.id !== 46 &&
                item.id !== 47 &&
                item.id !== 48 &&
                item.id !== 49 &&
                item.id !== 52
              ) {
                const detail = item?.detail;
                temp.push({
                  image: detail?.image,
                  name: detail?.name,
                  description: detail?.introduction_html,
                  link: routeUrls(item.datatype, item.id),
                  displayable: detail?.displayable,
                });
              }
            }
            child_data[child.id] = temp;
          });
          setChildData({ ...child_data });
        }
      }
    });
    httpclient.getLearningProgress().then((data) => {
      if (data && data.length !== 0) setLearningProgressData(data);
    });
  }, []);
  const carouselData = [
    {
      image:
        "https://cdn.datongbei.com/icdesign-1736837960993264f7247b4d7gc.jpg",
      name: "OSC",
      des: "Oscillator（振荡器），芯片系统中的节拍产生器，拉扎维课本第十三章内容，在芯片系统中，类属时钟模块。可以用来给芯片中数字模块提供节拍，可以给芯片中模拟电路例如ADC提供采样频率等等。本专栏将带大家从一个基础的OSC电路设计讲起，告诉大家时钟电路设计中各种仿真与设计技巧。",
      link: "/zhuanlans/36",
      price: 89,
      originalPrice: 159,
    },
    {
      image:
        "https://cdn.datongbei.com/icdesign-1736837977678f149h992dgcfb.jpg",
      name: "SAR ADC",
      des: "模拟与数字的桥梁，让我们从SAR ADC开始！",
      link: "/zhuanlans/51",
      price: 109,
      originalPrice: 199,
    },
  ];
  const carouselCard = useMemo(() => {
    return carouselData.map((item) => (
      <div className="carousel-card-item">
        <NavLink style={{ display: "flex" }} to={item.link}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexFlow: "column",
              width: "150px",
            }}
          >
            <img className="carousel-item-image" src={item.image} />
            <div className="carousel-item-name">{item.name}</div>
            <div className="carousel-item-des">{item.des}</div>
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "center",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
            }}
          >
            <div className="carousel-original-price">
              RMB {item.originalPrice}/年
            </div>
            <div className="carousel-price">RMB {item.price}/年</div>
            <div className="carousel-buy-button">
              <ShoppingCartOutlined /> 购买
            </div>
          </div>
        </NavLink>
      </div>
    ));
  }, [carouselData]);
  const categorieslist = useMemo(() => {
    if (CategoriesData) return List(CategoriesData.children);
  }, [CategoriesData]);
  const zhuanlansCard = useMemo(() => {
    return CategoriesData?.children?.map((item) => {
      if (!item?.detail?.displayable) {
        return;
      }
      const zhuanlans = childData?.[item.id]?.map((child) => {
        if (child.displayable === 0) {
          return (
            <div
              style={{ cursor: "not-allowed" }}
              className="zhuanlan-card-item"
            >
              <img className="zhuanlan-card-image" src={child.image} />
              <div className="zhuanlan-card-name">{child.name}</div>
              <div className="zhuanlan-card-des">{child.description}</div>
              <div className="zhuanlan-card-notice">即将开放...</div>
            </div>
          );
        } else {
          return (
            <NavLink to={child.link} className="zhuanlan-card-item">
              <img className="zhuanlan-card-image" src={child.image} />
              <div className="zhuanlan-card-name">{child.name}</div>
              <div className="zhuanlan-card-des">{child.description}</div>
            </NavLink>
          );
        }
      });
      return (
        <div className="list-card-item">
          <div className="list-name">{item.detail.name}</div>
          <div className="list-content">{zhuanlans}</div>
        </div>
      );
    });
  }, [CategoriesData, childData]);
  const navigationData = [
    {
      title: "早鸟价",
      link: "/?selected=early",
      key: "early",
    },
    // {
    //   title: "即将上线",
    //   link: "/?selected=soon",
    //   key: "soon",
    // },
  ];
  const learningData = useMemo(() => {
    return learningProgressData?.map((item) => {
      return {
        name: item.zhuanlan_name,
        progress: item.progress,
        link: "/chapters/" + item.chapter_id,
        time: moment(item.updated_at).format("YYYY-MM-DD"),
      };
    });
  }, [learningProgressData]);
  const learningCards = useMemo(() => {
    return learningData?.map((item) => (
      <div className="learning-card-item">
        <NavLink to={item.link}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="learning-card-name">{item.name}</div>
            {/*<div className="learning-card-time">{item.time}</div>*/}
          </div>
          <Progress
            trailColor="white"
            strokeColor="#486889"
            strokeWidth={4}
            percent={item.progress}
          />
        </NavLink>
      </div>
    ));
  }, [learningData]);
  const recommendData = [
    {
      name: "模拟设计——从二级运放开始",
      price: 159,
      des: "模拟设计之行，以此为始。本专栏兼顾严谨性、趣味性，以文字的形式探讨，以视频的形式实操，从深入分析与设计一个二级运放开始，带领大家开启模拟电路设计的新征程。",
      link: "/zhuanlans/30",
    },
    {
      name: "运放进阶",
      price: 159,
      des: "运放的进阶篇中，不同于入门篇对通用模拟spec的逐一介绍，我们将更多地探讨运放本身作为一个模块所承载的使命，笔者将带领同学们重走运放结构的发展脉络，详细分析套筒与折叠运放，以及二级运放的各种补偿技术 。",
      link: "/zhuanlans/54",
    },
  ];
  const recommendCards = useMemo(() => {
    return recommendData.map((item) => {
      return (
        <div className="recommend-card-item">
          <NavLink to={item.link}>
            <div className="recommend-item-name">{item.name}</div>
            <div className="recommend-item-price">RMB {item.price}/年</div>
            <div className="recommend-item-des">{item.des}</div>
            <div className="recommend-buy-button">
              <ShoppingCartOutlined /> 购买
            </div>
          </NavLink>
        </div>
      );
    });
  }, [recommendData]);
  return (
    <div className="home">
      <SimpleScrollBar>
        <div className="home-wrapper-pc">
          <div className="home-carousel">
            <div className="home-carousel-nav">
              {NavigationBar(navigationData, selected ? selected : "early")}
              <div className="home-carousel-cards">
                {carouselData && carouselCard}
              </div>
            </div>
          </div>
          <div className="home-bottom-wrapper">
            <div className="home-zhuanlan-list">{zhuanlansCard}</div>
            <div className="home-sidebar">
              {!!learningProgressData && <div className="home-sidebar-item">
                <div className="home-sidebar-title">
                  我正在学
                  <NavLink to="/user/center/general" style={{ float: "right" }}>
                    <RightOutlined style={{ color: "#486889" }} />
                  </NavLink>
                </div>
                <div className="home-sidebar-learning">{learningCards}</div>
              </div>}
              <div className="home-sidebar-item">
                <div className="home-sidebar-title">推荐课程</div>
                <div className="home-sidebar-recommend">{recommendCards}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-wrapper-mobile">
          <img
            className="home-logo"
            src="https://cdn.datongbei.com/icdesign-logo.png"
            alt="icdesign"
          />
          <div className="slogan-wrapper">
            <div className="line"></div>
            <div className="slogan">
              &#123; upon this rock, we will build our world &#125;
            </div>
          </div>
          <div className="lists-wrapper">{categorieslist}</div>
        </div>
      </SimpleScrollBar>
    </div>
  );
}

export default Home;
