import { RouterRefHolder } from "../utils/value";
import { notification } from "antd";

class HttpClient {
  constructor() {
    this.server_url = window.location.href.startsWith("http://localhost")
      ? "http://127.0.0.1:8333"
      : "https://server.icdesign.com";
    //  this.server_url = "https://server.icdesign.com";
  }

  async Request(options) {
    if (
      options.auth !== "true" ||
      (options.auth === "true" && localStorage.token)
    ) {
      return fetch(
        options.url ? options.url : this.server_url + options.input,
        {
          method: options.method || "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${localStorage.token}`,
          },
          body: JSON.stringify(options.body),
        }
      )
        .then(async (response) => {
          const data = await response.json();
          if (response.status === 200 || response.status === 201) {
            if (data.time_expired && options.alert) {
              notification.info({
                duration: null,
                message: "过期提示",
                description: (
                  <div>
                    <span>您购买的</span>
                    <span style={{ color: "#5995DA", fontWeight: "bold" }}>
                      {data.renew_product_name}
                    </span>
                    <span>已过期，请点击下方续费享续费优惠福利</span>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      <a
                        href={data.renew_link}
                        style={{
                          width: "80%",
                          color: "white",
                          fontWeight: "bold",
                          background: "#5995DA",
                          textAlign: "center",
                          padding: "4px 12px",
                          borderRadius: "10px",
                        }}
                      >
                        续费
                      </a>
                    </div>
                  </div>
                ),
                key: "renew",
              });
            }
            return data;
          }

          if (
              (response.status === 401 && options.alert === true) ||
            (response.status === 403 && data.reason === "invalid_token")
          ) {
            if (window.location.pathname !== "/user/login")
              window.loginRedirectUrl = window.location.pathname;
            if (options.alert === true) {
              notification.info({
                message: "登录提示",
                description: "请先登录后查看内容",
                key: "please_login_first",
              });
            }
            (await RouterRefHolder.getNonNullValue()).history.push(
              "/user/login"
            );
          }
          throw data;
        })
        .catch((ex) => {
          if (options.alert === true && ex.errMsg) {
            notification.error({
              message: "错误提醒",
              description: ex.errMsg,
            });
          }
          throw ex;
        });
    } else {
      if (window.location.pathname !== "/user/login")
        window.loginRedirectUrl = window.location.pathname;
      if (options.alert === true) {
        console.log(options);
        notification.info({
          message: "登录提示",
          description: "请先登录后查看内容",
          key: "please_login_first",
        });
      }
      (await RouterRefHolder.getNonNullValue()).history.push("/user/login");
    }
  }
  verifyToken() {
    return this.Request({
      input: "/login/verify/token",
      method: "POST",
      alert: false,
      auth: "false",
    });
  }
  getCategoriesData(categoriesid) {
    return this.Request({
      input: "/nodes/" + categoriesid,
      alert: false,
      auth: "false",
    });
  }

  getZhuanlansData(zhuanlansid, alert) {
    return this.Request({
      input: "/zhuanlans/" + zhuanlansid,
      alert: alert,
      auth: "false",
    });
  }
  getChaptersData(chaptersid) {
    return this.Request({
      input: "/chapters/" + chaptersid,
      method: "POST",
      alert: true,
      auth: "false",
    });
  }
  getChaptersPreviewData(chaptersid) {
    return this.Request({
      input: "/chapters/" + chaptersid,
      method: "POST",
      body: { mode: "preview" },
      alert: false,
      auth: "false",
    });
  }

  getTestPaperData(testpaperid) {
    return this.Request({
      input: "/testpapers/" + testpaperid,
      alert: true,
      auth: "false",
    });
  }
  getTestPaperCollectionData(testpaper_collection_id) {
    return this.Request({
      input: "/testpaper_collection/" + testpaper_collection_id,
      method: "GET",
      alert: false,
      auth: "false",
    });
  }
  getTestPaperCollectionInfo(testpaper_collection_id) {
    return this.Request({
      input: "/testpaper_collection/info",
      method: "POST",
      body: { id: testpaper_collection_id },
      alert: false,
      auth: "true",
    });
  }
  getVerificationCode(mobile) {
    return this.Request({
      input: "/login/send_sms",
      method: "POST",
      body: { mobile: mobile },
      alert: true,
      auth: "false",
    });
  }

  getUserInfo() {
    return this.Request({
      input: "/user/info",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  Login(mobile, code, scope) {
    return this.Request({
      input: "/login/verify",
      method: "POST",
      body: { mobile: mobile, code: code, scope: scope },
      alert: true,
      auth: "false",
    });
  }
  LoginBot(account, password) {
    return this.Request({
      input: "/login/bot/verify",
      method: "POST",
      body: { account, password },
      alert: true,
      auth: "false",
    });
  }

  updateUserName(name) {
    return this.Request({
      input: "/user/name/update",
      method: "POST",
      body: { name: name },
      alert: false,
      auth: "true",
    });
  }
  updateUserInfo(values) {
    return this.Request({
      input: "/user/info/update",
      method: "POST",
      body: values,
      alert: false,
      auth: "true",
    });
  }

  getUserResume() {
    return this.Request({
      input: "/resume/all",
      alert: true,
      auth: "true",
    });
  }

  updateUserResume(fieldName, values) {
    return this.Request({
      input: "/resume/all",
      method: "POST",
      alert: true,
      auth: "true",
      body: {
        fieldName: fieldName,
        data: values,
      },
    });
  }

  getVideoPlayInfo(videoAccessId) {
    return this.Request({
      input: "/video/play",
      method: "POST",
      alert: true,
      body: {
        accessId: videoAccessId,
      },
    });
  }

  getUserPossessTestpaper() {
    return this.Request({
      input: "/user/possess_testpaper/all",
      method: "POST",
      alert: false,
      auth: "true",
    });
  }

  getUserPossessTestpaperCollection() {
    return this.Request({
      input: "/user/possess_testpaper_collection/all",
      method: "POST",
      alert: false,
      auth: "true",
    });
  }
  possessZhuanlan(zhuanlanid, alert) {
    return this.Request({
      input: "/user/possess_zhuanlan",
      method: "POST",
      alert: alert !== false,
      auth: "true",
      body: { zhuanlan_id: zhuanlanid },
    });
  }
  possessCategory(categoryid, alert) {
    return this.Request({
      input: "/user/possess_category",
      method: "POST",
      alert: alert !== false,
      auth: "true",
      body: { category_id: categoryid },
    });
  }
  possessTestpaper(testpaperid) {
    return this.Request({
      input: "/user/possess_testpaper",
      method: "POST",
      alert: true,
      auth: "true",
      body: { testpaper_id: testpaperid },
    });
  }
  possessTestpaperCollection(testpape_collectionid, alert) {
    return this.Request({
      input: "/user/possess_testpaper_collection",
      method: "POST",
      alert: alert !== false,
      auth: "true",
      body: { testpaper_collection_id: testpape_collectionid },
    });
  }
  getQiniuUploadToken() {
    return this.Request({
      input: "/site-config/get-qiniu-upload-token",
      method: "POST",
      alert: true,
      auth: "true",
      body: {},
    });
  }

  downloadResumePdf() {
    return fetch(this.server_url + "/resume/download", {
      method: "GET",
      headers: {
        Authorization: `${localStorage.token}`,
      },
    }).then((res) => {
      res.blob().then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const filename = "个人简历.pdf";
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      });
    });
  }

  getCourses() {
    return this.Request({
      input: "/user/courses",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  addGift(data_type, data_id) {
    return this.Request({
      input: "/gift/add",
      method: "POST",
      body: { data_type: data_type, data_id: data_id },
      alert: true,
      auth: "true",
    });
  }
  getGiftData(token) {
    return this.Request({
      input: "/gift",
      method: "POST",
      body: { token: token },
      alert: true,
      auth: "false",
    });
  }
  getGiftsData() {
    return this.Request({
      input: "/gifts",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }
  receiveGift(token) {
    return this.Request({
      input: "/gift/receive",
      method: "POST",
      body: { token: token },
      alert: true,
      auth: "true",
    });
  }
  getQrCode(url) {
    return this.Request({
      input: "/get/qrcode",
      method: "POST",
      body: { url: url },
      alert: true,
      auth: "true",
    });
  }
  getWxConfigParams(url) {
    return this.Request({
      input: "/wxauth/getWxConfigParams",
      method: "POST",
      body: { url: url },
      alert: true,
      auth: "true",
    });
  }
  getRelatedComments(data_type, data_id) {
    return this.Request({
      input: "/comment/get-related",
      method: "POST",
      body: { data_type, data_id },
      alert: true,
      auth: "true",
    });
  }
  addCommentLikes(comment_id) {
    return this.Request({
      input: "/comment/likes",
      method: "POST",
      body: { comment_id: comment_id },
      alert: true,
      auth: "true",
    });
  }
  addCommentDislikes(comment_id) {
    return this.Request({
      input: "/comment/dislikes",
      method: "POST",
      body: { comment_id: comment_id },
      alert: true,
      auth: "true",
    });
  }
  deleteComment(comment_id) {
    return this.Request({
      input: "/comment/delete",
      method: "POST",
      body: { comment_id: comment_id },
      alert: true,
      auth: "true",
    });
  }
  addComment(comment_content, data_type, data_id) {
    return this.Request({
      input: "/comment/add",
      method: "POST",
      body: { comment_content: comment_content, data_type: data_type, data_id },
      alert: true,
      auth: "true",
    });
  }
  getMessages() {
    return this.Request({
      input: "/message/show",
      method: "GET",
      alert: true,
      auth: "true",
    });
  }
  getUnreadMessagesCnt() {
    return this.Request({
      input: "/message/unread-cnt",
      method: "GET",
      alert: false,
      auth: "true",
    });
  }
  deleteMessage(message_id) {
    return this.Request({
      input: "/message/delete",
      method: "POST",
      body: { message_id: message_id },
      alert: true,
      auth: "true",
    });
  }
  deleteOpenid() {
    return this.Request({
      input: "/wxauth/delete/wechat_openid",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }
  getAllPosts() {
    return this.Request({
      input: "/hr/show_all",
      method: "POST",
      alert: true,
      auth: "false",
    });
  }
  getPostData(post_id) {
    return this.Request({
      input: "/hr/show",
      method: "POST",
      body: { post_id: post_id },
      alert: true,
      auth: "false",
    });
  }
  openRecruit() {
    return this.Request({
      input: "/user/open_recruit",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  closeRecruit() {
    return this.Request({
      input: "/user/close_recruit",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }
  submitResume(post_id) {
    return this.Request({
      input: "/resume/add",
      method: "POST",
      body: { post_id: post_id },
      alert: true,
      auth: "false",
    });
  }
  getCoupons() {
    return this.Request({
      input: "/coupon/show",
      method: "GET",
      alert: true,
      auth: "true",
    });
  }
  getCalculatedPrice(
    data_id,
    data_type,
    renew_price_mode,
    coupons,
    recommend_code
  ) {
    return this.Request({
      input: "/order/price",
      method: "POST",
      body: { data_id, data_type, renew_price_mode, coupons, recommend_code },
      alert: true,
      auth: "true",
    });
  }
  getUnreadCouponsMessage() {
    return this.Request({
      input: "/coupon/message",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  checkIsFirstPurchase() {
    return this.Request({
      input: "/user/first_purchase",
      method: "POST",
      alert: true,
      auth: "true",
    });
  }
  createDiscuss(discuss_title, discuss_html, discuss_tag) {
    return this.Request({
      input: "/discuss/create",
      method: "POST",
      body: { discuss_title, discuss_html, discuss_tag },
      alert: true,
      auth: "true",
    });
  }

  updateDiscuss(discuss_title, discuss_html, discuss_tag, discuss_id) {
    return this.Request({
      input: "/discuss/update",
      method: "POST",
      body: { discuss_title, discuss_html, discuss_tag, discuss_id },
      alert: true,
      auth: "true",
    });
  }
  createDiscussDraft(discuss_title, discuss_html, discuss_tag) {
    return this.Request({
      input: "/discuss/create_draft",
      method: "POST",
      body: { discuss_title, discuss_html, discuss_tag },
      alert: true,
      auth: "true",
    });
  }

  updateDiscussDraft(discuss_title, discuss_html, discuss_tag, discuss_id) {
    return this.Request({
      input: "/discuss/update_draft",
      method: "POST",
      body: { discuss_title, discuss_html, discuss_tag, discuss_id },
      alert: true,
      auth: "true",
    });
  }

  getDiscussData() {
    return this.Request({
      input: "/discuss/show",
      method: "GET",
      alert: false,
      auth: "false",
    });
  }
  getDiscussDetailData(discuss_id) {
    return this.Request({
      input: "/discuss/detail",
      method: "POST",
      body: { discuss_id },
      alert: false,
      auth: "false",
    });
  }
  getMyDiscussData() {
    return this.Request({
      input: "/discuss/my",
      method: "GET",
      alert: false,
      auth: "true",
    });
  }
  getMyDiscussDraftData() {
    return this.Request({
      input: "/discuss/my_draft",
      method: "GET",
      alert: false,
      auth: "true",
    });
  }

  getDiscussDraftDetailData(discuss_id) {
    return this.Request({
      input: "/discuss/detail_draft",
      method: "POST",
      body: { discuss_id },
      alert: false,
      auth: "true",
    });
  }

  addDiscussLikes(discuss_id) {
    return this.Request({
      input: "/discuss/like",
      method: "POST",
      body: { discuss_id },
      alert: false,
      auth: "true",
    });
  }

  delDiscussLikes(discuss_id) {
    return this.Request({
      input: "/discuss/dislike",
      method: "POST",
      body: { discuss_id },
      alert: false,
      auth: "true",
    });
  }
  deleteDiscuss(discuss_id) {
    return this.Request({
      input: "/discuss/delete",
      method: "POST",
      body: { discuss_id },
      alert: false,
      auth: "true",
    });
  }
  deleteDiscussDraft(discuss_id) {
    return this.Request({
      input: "/discuss/delete_draft",
      method: "POST",
      body: { discuss_id },
      alert: false,
      auth: "true",
    });
  }

  getOneRecruitmentQuestion(id) {
    return this.Request({
      input: "/recruitment-questions/one",
      body: { id },
      method: "POST",
      alert: true,
      auth: "true",
    }).then((q) => {
      if (!q) return null;
      return q;
    });
  }
  getAllVisibleRecruitmentQuestion() {
    return this.Request({
      input: "/recruitment-questions/all-visible",
      method: "GET",
      alert: true,
      auth: "true",
    });
  }
  getHottestRecruitmentQuestions() {
    return this.Request({
      input: "/recruitment-questions/hottest",
      method: "POST",
      alert: true,
      auth: "true",
    }).then((qs) => qs ?? []);
  }

  getRecruitmentQuestionAnswerByQuestion(questionId) {
    return this.Request({
      input: "/recruitment-question-answers/get-by-question",
      body: { questionId },
      method: "POST",
      alert: true,
      auth: "true",
    }).then((res) => {
      const answer = res?.answer;
      if (!answer) return null;
      answer.content = JSON.parse(answer.content);
      return answer;
    });
  }
  saveRecruitmentQuestionAnswer(questionId, content) {
    return this.Request({
      input: "/recruitment-question-answers/save",
      body: { questionId, content },
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  getRecruitmentQuestionDiscussionDraftByQuestion(questionId) {
    return this.Request({
      input: "/recruitment-question-discussions/draft-by-question",
      body: { questionId },
      method: "POST",
      alert: true,
      auth: "true",
    }).then((res) => {
      const discussion = res?.discussion;
      if (!discussion) return null;
      discussion.content = JSON.parse(discussion.content);
      return discussion;
    });
  }
  saveRecruitmentQuestionDiscussionDraft(questionId, type, title, content) {
    return this.Request({
      input: "/recruitment-question-discussions/save-draft",
      body: { questionId, type, title, content },
      method: "POST",
      alert: true,
      auth: "true",
    });
  }
  publishRecruitmentQuestionDiscussionDraft(discussionId) {
    return this.Request({
      input: "/recruitment-question-discussions/publish-draft",
      body: { discussionId },
      method: "POST",
      alert: true,
      auth: "true",
    });
  }
  getAllRecruitmentQuestionPublicDiscussionsByQuestion(questionId) {
    return this.Request({
      input: "/recruitment-question-discussions/public-by-question",
      body: { questionId },
      method: "POST",
      alert: true,
      auth: "true",
    }).then((discussions) => {
      if (!discussions) return [];
      return discussions.map((s) => ({ ...s, content: JSON.parse(s.content) }));
    });
  }
  getOneRecruitmentQuestionPublicDiscussionById(discussionId) {
    return this.Request({
      input: "/recruitment-question-discussions/public-by-id",
      body: { discussionId },
      method: "POST",
      alert: true,
      auth: "true",
    }).then((discussion) => {
      if (!discussion) return null;
      return { ...discussion, content: JSON.parse(discussion.content) };
    });
  }

  updateBestAnswerRecord(data_type, data_id, comment_id, comment_user_id) {
    return this.Request({
      input: "/best-answers/update",
      body: { data_type, data_id, comment_id, comment_user_id },
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  getAllKnowledgeTags() {
    return this.Request({
      input: "/knowledge-tags/all",
      method: "GET",
      alert: true,
      auth: "true",
    });
  }

  getUserRightsData() {
    return this.Request({
      input: "/rights/show",
      method: "GET",
      alert: true,
      auth: "true",
    });
  }

  sendUserBrowsingData(content_type, content_id) {
    return this.Request({
      input: "/data-analytics/user-browse",
      body: { content_type, content_id },
      method: "POST",
      alert: true,
      auth: "true",
    });
  }

  addLike(data_type, data_id, user_id) {
    return this.Request({
      input: "/likes/add",
      method: "POST",
      body: { data_type, data_id, user_id },
      alert: true,
      auth: "true",
    });
  }

  cancelLike(data_type, data_id) {
    return this.Request({
      input: "/likes/cancel",
      method: "POST",
      body: { data_type, data_id },
      alert: true,
      auth: "true",
    });
  }
  getWechatQrCode() {
    return this.Request({
      url: "https://kanjia.tuozhanpian.com:8282",
      input: "/qrcodes/icdesign",
      method: "GET",
      alert: true,
      auth: "true",
    });
  }
  getLearningProgress(){
    return this.Request({
      input: "/user/learning_progress",
      method: "POST",
      auth: "false",
    });
  }
}
const httpclient = new HttpClient();

export default httpclient;
